// Core variables and mixins
@import "variables.less"; // Modify this for custom colors, font-sizes, etc
@import "mixins.less";
@import "progress-bars.less";
@import "wells.less";

/********* MooTree *********/
#mootree_container{
    margin-top:0
}

#mytree{
    margin:10px 0;
    overflow:hidden;
    width:100%
}

.mooTree_node {
    font-family: Verdana, Arial, Helvetica;
    font-size: 77%;
    white-space: nowrap;
}

.mooTree_text {
    padding-top: 3px;
	height:15px;
    line-height: 12px;
	cursor: pointer;
	overflow:hidden;
}

.mooTree_img {
    float: left;
    width: 18px;
    height: 18px;
    overflow: hidden;
}

.mooTree_selected    {
    background-color: #e6f2f9;
    background-image:url(../images/white_arrow.gif);
    background-repeat:no-repeat;
    font-weight: bold;
}

/********* Navigation *********/
#rdfreenav{      
    width:520px;/*520 624*/
    text-align:left;
    height:71px;
    overflow:hidden;
    padding-left:0px;
    
    margin:0px auto 15px;
}
#rdfreenav ul{
    width:560px;/*560 629 659 803*/
    height:71px;
    margin:0 auto;
    padding:0
}
#rdfreenav ul li{    
    margin:0 0px 0 0 !important;
    padding:0;
    display:block;
    float:left;
    text-indent: -9999px
}
#rdfreenav ul li a {
    height:71px;   
    display:block;
    border:0;

    background:transparent url(../images/real_achiever_nav.jpg) no-repeat;
    background-image:url(../images/real_achiever_nav.jpg)  !important;

}        
#rdfreenav ul li.last a,#rdfreenav ul li.first a{ margin-left:0 }
#rdfreenav ul li a:focus { -moz-outline: none }

#rdfreenav div{ margin: 10px 0}

#rdfreenav .navigation{
    background:url(../images/rd_free_nav_bg.jpg) repeat-x 50% 50%
}




/* Alternate */
/* #rdfreenav ul li#rdfreenav_2{ display:none } /* Hide Dream Level */

/*#achiever-nav{ margin-top:5px; }*/
li#achiever-nav-1{ display: none }
/*li#achiever-nav-2{ display: none }*/
@media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
    li#achiever-nav-2{ display: none }
}


.icon-2{background-position:-120px 0}
.icon-3{background-position:-288px 0}
.icon-4{background-position:-432px 0}
.icon-5{background-position:0 -72px}
.icon-6{background-position:-96px -48px}
.icon-7{background-position:-192px -120px}






/*Welcome*/
li#rdfreenav_1 a{ display:none }
/*li#rdfreenav_1 a{ width:104px; background-position: 0px -71px !important }
li#rdfreenav_1.active a,li#rdfreenav_1.active a:hover,li#rdfreenav_1.active a:focus{ background-position: 0px -0px !important; }
li#rdfreenav_1 a:hover,li#rdfreenav_1 a:focus{ background-position: 0px 0px !important; }*/

/*Dream*/
li#rdfreenav_2 a{ width:104px; background-position: -104.83px -71px !important; }
li#rdfreenav_2.active a,li#rdfreenav_2.active a:hover,li#rdfreenav_2.active a:focus{ background-position: -104.83px 0px !important; }
li#rdfreenav_2 a:hover,li#rdfreenav_2 a:focus{ background-position: -104.83px 0px !important; }

/*Goal*/
li#rdfreenav_3 a{ width:104px; background-position: -209.66px -71px !important; }
li#rdfreenav_3.active a,li#rdfreenav_3.active a:hover,li#rdfreenav_3.active a:focus{ background-position: -209.66px -0px !important; }
li#rdfreenav_3 a:hover,li#rdfreenav_3 a:focus{ background-position: -209.66px -0px !important; }

/*Project*/
li#rdfreenav_4 a{ width:104px; background-position: -314.49px -71px !important; }
li#rdfreenav_4.active a,li#rdfreenav_4.active a:hover,li#rdfreenav_4.active a:focus{ background-position: -314.49px -0px !important; }
li#rdfreenav_4 a:hover,li#rdfreenav_4 a:focus{ background-position: -314.49px -0px !important; }        

/*Action*/
li#rdfreenav_5 a{ width:104px; background-position: -419.32px -71px !important; }
li#rdfreenav_5.active a,li#rdfreenav_5.active a:hover,li#rdfreenav_5.active a:focus{ background-position: -419.32px -0px !important; }
li#rdfreenav_5 a:hover,li#rdfreenav_5 a:focus{ background-position: -419.32px -0px !important; }    

/*Overview*/
li#rdfreenav_6 a{ width:104px; background-position: -524.15px -71px !important; }
li#rdfreenav_6.active a,li#rdfreenav_6.active a:hover,li#rdfreenav_6.active a:focus{ background-position: -524.15px -0px !important; }
li#rdfreenav_6 a:hover,li#rdfreenav_6 a:focus{ background-position: -524.15px -0px !important; }    

/*Calendar*/
li#rdfreenav_7 a{ width:104px; background-position: -628.98px -71px !important; }
li#rdfreenav_7.active a,li#rdfreenav_7.active a:hover,li#rdfreenav_7.active a:focus{ background-position: -628.98px -0px !important; }
li#rdfreenav_7 a:hover,li#rdfreenav_7 a:focus{ background-position: -628.98px -0px !important; }    


li.dream_builder_item div{ display:none }
/*li.dream_builder_item div input{height:15px;width:360px}*/
/*li.dream_builder_item div textarea{
    margin-top:7px;
    width:376px;
    height:81px;
    border:0 !important;
    overflow:scroll;
}
*/

    #dream_achiever .accordion-inner{ padding:5px; background: #fff }
    #dream_achiever .accordion-inner p{ font-size:93% }
    
    .tooltip-inner{ max-width: 500px; }
    #achiever-nav .nav-pills>li:nth-of-type(2) { margin-left: 0 }
    @media (max-width: @screen-xs-max){
        #sidebar.sidebar-offcanvas {
            padding: 5px;
        }
    }

#dream_achiever{
  /*margin:10px auto 0;
    width: 950px;*/
    /*position:relative*/
}

#dream_achiever label.required,#dream_achiever label.invalid { color:#900 !important }

#dream_calendar{
    background:url(../images/loading.gif) no-repeat 50% 50%
}

button.calendar{ margin-left:5px }

.content_tip{
    &:extend(.well);
    &:extend(.well-sm);
}
  
.content_tip h4{
    color: @brand-primary;
    font-style:italic; font-size: @font-size-h4; font-weight: 200; line-height:20px;
    text-shadow: 0 1px #fff;
}
  
.content_tip p{
    font-style:italic;
}

/*** Dream Achievers Form ***/

/*#dream_achiever #message{ cursor:pointer; font-weight:bold; text-align:center; height:100%; font-size:13px; line-height:2em }*/
#dream_achiever #message.success{ color:@state-success-text }
#dream_achiever #message.error{ color:@state-danger-text }

#dream_achiever .form-row,#dream_achiever .form-row-short{ margin:0px 0; text-align:left }
/*#dream_achiever .form-row input,#dream_achiever .form-row textarea{ width:95%;  }
#dream_achiever .form-row-short input,#dream_achiever .form-row-short textarea{ width:75%; }*/
#dream_achiever .form-row-buttons{ margin-top:10px; text-align:center; }
/*#dream_achiever .form-row label,#dream_achiever .form-row-short label,#dream_achiever .form-row-buttons #label{
    	display:inline;
    	font-weight:bold;
    	font-size:100%;	
    	color:#000;
    	line-height:1.6em	
}
#dream_achiever .form-row input,#dream_achiever .form-row-short input{
	border-right:1px solid #eee;
	border-bottom:1px solid #eee;
	border-left:1px solid #ccc;
	border-top:1px solid #ccc;	
	color:#555;
	font-size:100%;
	padding:.33em 0 0 .33em;
	line-height:1.1em;
	height:1.4em
}
#dream_achiever .form-row select{
	color:#555;
	width:97%;
	font-size:100%;
	padding:2px;
}
#dream_achiever .form-row textarea{
	color:#555;
	padding:.33em 0 0 .33em;
	font-size:100%
}


#dream_achiever .form-row input.checkbox{ width:15% }
*/

#dream_achiever .cartoon{
}

#dream_achiever #columns{
    background:url(../images/fading_corner_topleft2.jpg) no-repeat 0 0; padding: 5px 0 0 10px;
}
#dream_achiever #overview{
    background:url(../images/fading_corner_bottomright2.jpg) no-repeat 10px 100%; padding-bottom:10px;
}
#dream_achiever #body{
   margin-top:0;
   padding-top:0;
}

#container #dream_achiever #body form label{ font-weight:bold; color:#333; margin-top:10px }
#container #dream_achiever #body form label.first{ margin-top:0 }
#container #dream_achiever #body form input{
}
#container #dream_achiever #body form tfoot input{
    padding:0; margin:0
}

#container #dream_achiever #body form .form-row-short input{ float:left }
#container #dream_achiever #body form .form-row-short label{ color:#333 }

/*** Sliders ***/
.knob {
    height: 15px;
    width: 15px;
    overflow:hidden;
    background: @brand-primary;
    cursor:pointer;
    border-radius:3px;
}
 

.slider {
    cursor: pointer;
}

#event_progress_slider {
}
#event_progress_slider div {
    &:extend(.progress-bar);
    cursor:pointer !important;
    -moz-user-select: -moz-none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &:extend(.progress-bar-striped);
    &:extend(.progress-bar-info);
}

#dream_achiever button{ display:inline }

#media.video{
    position: absolute;
    width:100%;
    right: 0;
    bottom: 0;
    height: 355px;
    visibility:visible;
}

#da_video_container{
    text-align:center;

    margin:0 auto;
    width: 950px;

    background:url(../images/rd_free_gameboard_track.gif) no-repeat 50% 100%;
    border:0px solid;
    cursor:pointer
}

#status_text{
    color:#fff;
    font-weight:normal;
    cursor:pointer;
    height:35px;
    padding-left:10px;
    font-size:131%;
    overflow:hidden;
    line-height:2.0em;
    /*position:relative;*/
}


#status_text .status_icon{
    float:right;
    margin-right:7px;
    margin-top:-6px;
}

#rat_talk{    
    &:extend(.well);
    padding: 5px;
    border-radius: @border-radius-base; 
}
#rat_talk p{ margin:0 }
#rat_talk_text{ margin-top:0 !important; padding:0 5px; font-size:12px !important; display:block }
#rat_talk_text b,#rat_talk_text strong{ color:@state-success-text !important; display:block; font-size:100% !important; margin-bottom:5px; }
#rat_talk_text b { font-size: 15px !important; }
#rat_talk_text a { text-decoration: underline; }


#body .alert, #resources{
    margin-top: 10px
}

.panel-active{
    border-color: @btn-primary-border;
}
.panel-active>.panel-heading {
    color: #fff;
    background-color: @brand-primary;
    border-color: @btn-primary-border;
}

#da_video_container img { vertical-align:super; }
